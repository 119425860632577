import { useState, useEffect} from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../images/UBFLOGO.png';
import MenuItem from '@mui/material/MenuItem';

export default function Header() {
  const navigate = useNavigate();
  
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1450);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
    {isDesktop ? (
    <Main>
      <Divs>
        <Image src={Logo} alt="UBF Logo" onClick={() => navigate('/')}/>
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/about">About Us</StyledLink>
        <StyledLink to="/iolta">IOLTA</StyledLink>
        <StyledLink to="/projects">Projects</StyledLink>
        <StyledLink to="/forms">Forms/Rules</StyledLink>
      </Divs>
    </Main>
    ) : (
      <Main>
        <MenuItem sx={{ borderBottom: 1 }}><StyledLink to="/">Home</StyledLink></MenuItem>
        <MenuItem sx={{ borderBottom: 1 }}><StyledLink to="/about">About Us</StyledLink></MenuItem>
        <MenuItem sx={{ borderBottom: 1 }}><StyledLink to="/iolta">IOLTA</StyledLink></MenuItem>
        <MenuItem sx={{ borderBottom: 1 }}><StyledLink to="/projects">Projects</StyledLink></MenuItem>
        <MenuItem><StyledLink to="/forms">Forms/Rules</StyledLink></MenuItem>
      </Main>
    )}
    </>
  );
}

const Image = styled.img`
  max-height: 100px;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
`;
const Main = styled.div`
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
`;

const Divs = styled.div`
  margin: 1.5rem 1rem;
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: black;
  font-size: 1.5rem;
  padding: 0px 20px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  :not(:first-child) {
    border-left: 2px solid #aeab8a;
  }

  @media (max-width: 955px) {
    font-size: 1rem;
  }
`;
