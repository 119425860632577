import React from 'react';
import styled from 'styled-components';
import FileViewer from './fileViewer';
import summary from '../pdfs/UBFJusticeGapExecSummary.pdf';
import fullReport from '../pdfs/UBFJusticeGapFullReport.pdf';
import YouTube from 'react-youtube';
import cover from '../images/ubf2022cover.JPG';
import utah from '../images/utah.png';
import report from '../pdfs/UBF2022.pdf'
import { Link } from 'react-router-dom';

export default function Projects() {
  let opts;

  let width = window.innerWidth
  if(width > 1080){
    opts = {
      height: '300',
      width: '500',
    };
  } else {
    opts = {
      height: '200',
      width: '300',
    };
  }
  return (
    <Main>
      <Title>Projects</Title>
      <TextBox2>
        <Body3>
          The Utah Bar Foundation is pleased to release a report on the findings
          of our study of the eviction and debt collection legal system in Utah
          Courts. Thanks to the support of The Pew Charitable Trusts and the
          work of the Civil Legal System Modernization team, the report titled
          <i> Utah Bar Foundation Report on Debt Collection and Utah's Courts</i> can be
          found <a href={report} target = "_blank">here.</a>
        </Body3>
        <Body2>
          <a href={report} target = "_blank" style={{textAlign: 'center'}}><Image2 src={cover} alt="cover of 2022 report" /></a>
        </Body2>
      </TextBox2>
      <TextBox2>
          <Body3>Want to explore where eviction and debt collection cases are happening in Utah? Please use <a href={'https://public.tableau.com/app/profile/kim.paulding/viz/DefendantsMaps/SmallclaimsDashboard'} target = "_blank">these</a> interactive maps for more information. </Body3>
        <a href={'https://public.tableau.com/app/profile/kim.paulding/viz/DefendantsMaps/SmallclaimsDashboard'} target = "_blank" style={{textAlign: 'center'}}><UtahImage src={utah} alt="shape of utah" /></a>
        </TextBox2>
        <TextBox2>
          <Body3>
            In support of our mission to improve the administration of justice,
            the Utah Bar Foundation (UBF), in conjunction with the Utah
            Foundation, commissioned an unmet legal needs study that was completed
            in February 2020.
            <br />
            That report can be viewed here:
          </Body3>
        </TextBox2>
      <TopInfo>
        <FileViewer
          file={summary}
          release={'April 2020'}
          description={
            'Executive Summary of The Justice Gap: Addressing the Unmet Legal Needs of Lower-Income Utahns'
          }
        />
        <FileViewer
          file={fullReport}
          release={''}
          description={
            ' Full Report of The Justice Gap: Addressing the Unmet Legal Needs of Lower-Income Utahns'
          }
        />
        <TextBox>
          Due to COVID, the Utah Bar Foundation only released video
          presentations of the findings of this
        </TextBox>
      </TopInfo>
      <VideosContainer>
        <VideoContainer>
        <div style={{fontSize: '1.5rem'}}>Full Report Presentation</div>
        <YouTube
            videoId={'t09GDh82RSk'}
            opts={opts}
          />
          </VideoContainer>
          <VideoContainer>
          <div style={{fontSize: '1.5rem'}}>Executive Report Presentation</div>
          <YouTube
            videoId={'CbCxmQarlRU'}
            opts={opts}
          />
          </VideoContainer>
        </VideosContainer>
    </Main>
  );
}

const Main = styled.div`
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Title = styled.div`
  background-color: #8f7c71;
  padding: 1rem;
  border-radius: 30px 0 0 0;
  margin-left: 5rem;
  font-size: 2rem;
  color: white;
  border-right: 15px solid #9ad9ac;
`;

const TextBox = styled.div`
  box-sizing: border-box;
  padding: 10px;
  margin: 1% 5%;
  font-size: 1.2rem;
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6rem;
  @media (max-width: 768px) {
    margin-left: 0rem;
  }
`;

const VideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width:100%;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextBox2 = styled.div`
  box-sizing: border-box;
  padding: 10px 30px;
  margin: 1% 3%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Body2 = styled.p`
  display: flex;
  justify-content: center;
`;

const Body3 = styled.p`
  width: 100%;
  font-size: 1.3rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  color: black;
`;

const Image2 = styled.img`
width: 30%;
border: solid black 1px;
padding: 5px;
`;

const UtahImage = styled.img`
width: 20%;
border: solid black 1px;
padding: 5px;
`;