import React from 'react';
import styled from 'styled-components';
import Bank from './bank';
import FileViewer from './fileViewer';
import notice from '../pdfs/Notice.pdf';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Iolta() {
  return (
    <Main>
      <TopText>IOLTA</TopText>
      <TextContainer>
        Interest on Lawyers Trust Accounts, or “IOLTA”, is a program established
        by the Utah Supreme Court in 1983. It mandates that lawyers and law
        firms establish interest-bearing accounts to hold client funds. IOLTA
        accounts allow attorneys to pool client trust funds into one IOLTA
        account at an approved Utah IOLTA-eligible financial institution.
        <br />
        <br />
        If you will be holding a large sum of client funds or a sum of client
        funds for a long period of time, you should contact the Utah Bar
        Foundation for information on how to set up a separate interest bearing
        client trust account for the benefit of that individual client.
        <br />
        <br />
        Your financial institution might ask you for the Notice to Financial Institutions Form. You can access that form here:
        <FileViewer
          file={notice}
          description={
            'Notice to Financial Institutions'
          }
        />
      </TextContainer>
      <AccordionHolder>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              What Funds Should Be Placed in My IOLTA Account:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              All funds that you have not earned should be placed in your IOLTA
              client trust account. This might include:
              <br /> - Flat fees <br />- Prepaid client fees <br />- Retainers{' '}
              <br />- Settlements that you are responsible for distributing,
              etc.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Can I Keep Some of my Own Funds in the IOLTA Account to Cover Bank
              Fees?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A lawyer may deposit the lawyer’s own funds in a client trust
              account for the sole purpose of paying bank service charges on
              that account, but only in an amount necessary for that purpose.
              Accurate records must be kept regarding which part of the funds
              are the lawyer’s.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              What Financial Institution Can I Use to for my IOLTA Client Trust
              Account?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Financial institutions must voluntarily agree to the requirements
              to hold attorney client funds. The Utah Bar Foundation has
              approved the financial institutions below to open and maintain
              IOLTA client trust accounts because they have agreed to all of the
              requirements as outlined in Rule 1.15 and Rule 14-1001. As an
              attorney, Rule 1.15(a) states that: Funds shall be kept in a
              separate account maintained in the state where the lawyers office
              is situated or elsewhere with the consent of the client or third
              person. The account may only be maintained in a financial
              institution that agrees to report to the Office of Professional
              Conduct in the event any instrument in properly payable form is
              presented against an attorney trust account containing
              insufficient funds, irrespective of whether or not the instrument
              is honored.
            </Typography>
            <div>
              <br />
              PLEASE NOTE THAT IF YOUR OFFICE IS LOCATED OUT OF STATE, IT IS
              YOUR RESPONSIBILITY TO OBTAIN CONSENT FROM YOUR CLIENT TO HOLD
              THEIR FUNDS OUTSIDE OF UTAH AND TO VERIFY THAT YOUR FINANCIAL
              INSTITUTION HAS AGREED TO REPORT INSUFFICIENT FUNDS NOTICES TO
              OPC.
            </div>
          </AccordionDetails>
        </Accordion>
      </AccordionHolder>
      <Bank />
    </Main>
  );
}

const Main = styled.div`
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
`;
const AccordionHolder = styled.div`
  width: 75%;
  padding: 30px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const TopText = styled.div`
  background-color: #8f7c71;
  padding: 1rem;
  border-radius: 30px 0 0 0;
  margin-left: 5rem;
  font-size: 2rem;
  color: white;
  border-right: 15px solid #9ad9ac;
`;

const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 10px;
  margin: 0% 0%;
  font-size: 1.2rem;
  margin-left: 5rem;
  @media (max-width: 768px) {
    margin-left: 0rem;
  }
`;
