import './App.css';
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home"
import About from "./components/about"
import Iolta from './components/iolta';
import Projects from "./components/projects"
import Forms from './components/forms';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <Router>
      <>
      <Header />
        <Routes>
          <Route path="/iolta" element={<Iolta />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/projects" element={<Projects />}/>
          <Route path="/forms" element={<Forms />}/>
          <Route path="/" element={<Home />}/>
        </Routes>
      <Footer />
      </>
    </Router>
  );
}


export default App;
