import React from "react";
import styled from "styled-components";
import Logo from '../images/UBFLOGO.png'




export default function Footer() {
  return (
    <Main>
    <Image src={Logo} alt="UBF Logo" />
    <Text> 
    Utah Bar Foundation
    <br/>645 South 200 East
    <br/>Salt Lake City, Utah 84111
    <br/>Tel: <Link href="tel:8012977046">801-297-7046</Link>
    <br/>Email: <Link href="mailto:kim@utahbarfoundation.org">kim@utahbarfoundation.org</Link>
    </Text>
    </Main>
  );
}

const Image = styled.img`
max-height: 120px;
padding: 10px 20px;
box-sizing: border-box;

@media (max-width: 450px) {
  display: none;
  }
`

const Link = styled.a`
text-decoration: none;
color: white;
`

const Main = styled.div `
width: 85%;
margin: auto;
display: flex;
justify-content: center;
align-items: center;
background-color: #8F7C71;
border-right: 15px solid #9AD9AC;
box-sizing: border-box;

`

const Text = styled.div `
margin: 1.5rem 4rem;
color: white;

@media (max-width: 768px) {
  margin: 1rem .5rem;
  }
`