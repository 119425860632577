import React from 'react';
import styled from 'styled-components';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function fileViewer({ description, release, file, fontSize }) {

  const Info = styled.a`
  display: flex;
  background-color: #8f7c71b3;
  align-items: center;
  justify-content: space-around;
  font-size: ${fontSize ? fontSize: '1rem'};
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
`;

  return (
    <>
      <FileContainer>
        <Title>{release}</Title>
        <Info href = {file} target = "_blank">
          <PictureAsPdfIcon fontSize="large" />
          <div>{description}</div>
        </Info>
      </FileContainer>
    </>
  );
}

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 10px;
  font-size: 1.7rem;
`;



const Title = styled.div`
  display: flex;
`;
