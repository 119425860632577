import React from 'react';
import styled from 'styled-components';
import FileViewer from './fileViewer';
import certification from '../pdfs/IOLTAcertificationForm.pdf';
import LinkIcon from '@mui/icons-material/Link';
import notice from '../pdfs/Notice.pdf';


export default function Forms() {
  return (
    <Main>
      <Title>Forms/Rules</Title>
      <TopInfo>
        <TextBox>
          If you have recently changed firms, moved your IOLTA account, or opened a new
          account, please complete this form and email it to the Utah Bar
          Foundation so that we can update your attorney licensing record.
        </TextBox>
        <FileViewer
          file={certification}
          release={''}
          description={
            '2023-2024 Utah IOLTA Compliance Form'
          }
          fontSize={'1.5rem'}
        />
        <TextBox>
        If you are opening a new IOLTA account, your financial institution might
         ask you for the Notice to Financial Institutions Form. You can access 
         that form here. To see which financial institutions are certified to 
         offer IOLTA accounts, please click <a href={'/iolta'}>here.</a>
        </TextBox>
        <FileViewer
          file={notice}
          release={''}
          description={
            'Notice to Financial Institutions'
          }
          fontSize={'1.5rem'}
        />
        <TextBox>
        There are two rules that govern the handling of client funds being held by an attorney. Those rules can be found here:
        </TextBox>
        <HyperLink href='https://www.utcourts.gov/rules/view.php?type=ucja&rule=14-1001' target="_blank" rel="noopener noreferrer"><LinkIcon />Utah Court Rules of Professional Conduct Article 10. IOLTA Rule 14-1001. IOLTA</HyperLink>
        <HyperLink href='https://www.utcourts.gov/rules/view.php?type=ucja&rule=13-1.15' target="_blank" rel="noopener noreferrer"><LinkIcon />Utah Court Rules of Professional Conduct Chapter 13. Rule 1.15. Safekeeping Property</HyperLink>
      </TopInfo>
    </Main>
  );
}

const Main = styled.div`
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Title = styled.div`
  background-color: #8f7c71;
  padding: 1rem;
  border-radius: 30px 0 0 0;
  margin-left: 5rem;
  font-size: 2rem;
  color: white;
  border-right: 15px solid #9ad9ac;
`;

const TextBox = styled.div`
  box-sizing: border-box;
  margin: 1% 5%;
  font-size: 1.2rem;
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6rem;

  @media (max-width: 768px) {
    margin-left: 0rem;
  }
`;

const HyperLink = styled.a`
font-size: 1.5rem;
color: black;
padding: 10px;
`
