import React from 'react';
import styled from 'styled-components';

export default function Photo({ data }) {
  return (
    <>
      <DirectorContainer>
        <Image src={data.photo} alt="Board Director" />
        <Info>
            <div>{data.name}</div>
            <div>{data.position}</div>
            <div style={{textAlign: 'center'}}>{data.info}</div>
            </Info>
      </DirectorContainer>
    </>
  );
}

const Image = styled.img`
    min-width: 150px;
    margin: 20px;
    height: 200px;
    max-width: 60%;
    object-fit: cover;
`;

const DirectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;;
    padding: 10px 50px;
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
