import React from 'react';
import styled from 'styled-components';
import backsplash from '../images/slc.jpg';

export default function Home() {
  return (
    <Main>
      <Backsplash>
        <Image src={backsplash} alt="backsplash of city" />
      </Backsplash>
      <TextBox>
      <BoldBody>
      NOTICE: INFORMATION ABOUT FDIC/NCUA COVERAGE OF FUNDS HELD IN ATTORNEY TRUST ACCOUNTS
      </BoldBody>
      <Body>
      In light of recent bank failures, it is important for lawyers and law firms to understand how FDIC/NCUA 
      insurance applies to attorney client trust accounts, particularly IOLTA accounts holding funds for multiple 
      clients. You want to ensure that your client trust account has been properly set up as an IOLTA account 
      (or in some cases a NON-IOLTA client trust account) and has been titled as such.<br /> <i> To check if your account 
      is properly set up as an IOLTA account, please email us at  <a href="mailto:iolta@utahbar.org">iolta@utahbar.org</a> with the account name, name 
      of the financial institution and the last four digits of your account number.</i>
      </Body>
      <Body>
      Per the FDIC, trust accounts, including IOLTA accounts, are treated as fiduciary accounts, “deposit accounts owned by one party but held in a fiduciary capacity by another party.” For purposes of FDIC insurance, the FDIC requires (1) “the fiduciary nature of the account…be disclosed in the bank’s deposit account records” and (2) "name and ownership interest of each owner must be ascertainable from the deposit account records of the insured bank or from records maintained by the agent.” As long as FDIC requirements are met, each client is protected up to the standard deposit insurance limits, currently $250,000 per client per financial institution. If a client has funds in other accounts with the bank where the lawyer holds entrusted funds, that may affect whether the funds the lawyer holds for the client are insured to the deposit insurance limit.
      </Body>
      <Body>
      FDIC guidance on insurance can be found <a href='https://www.fdic.gov/resources/deposit-insurance/brochures/insured-deposits/'>here.</a>
      </Body>
      <Body>
      Further questions related to FDIC insurance or generally about client trust accounts can be sent to <a href="mailto:kim@utahbarfoundation.org">kim@utahbarfoundation.org</a> or by calling the Foundation office at 801-297-7046.
      </Body>
      <Body>
      Further questions related to a lawyer’s professional responsibility when acting as a fiduciary should be directed to the Ethics staff at <a href="mailto:ethicshotline@utahbar.org. ">ethicshotline@utahbar.org. </a>
      </Body>
        
        <Body style={{paddingTop: '40px'}}>
          The Utah Bar Foundation was founded in 1963 and exists today with a
          mission to:
          <UnorderedList>
            <li>
              Promote legal education and increase the knowledge and awareness
              of the law in Utah
            </li>

            <li>Assist in providing legal services to lower income Utahns</li>

            <li>Improve the administration of justice and</li>

            <li>Serve other worthwhile law-related public purposes</li>
          </UnorderedList>
        </Body>
      </TextBox>
    </Main>
  );
}

const Backsplash = styled.div`
  width: 100;
  margin: auto;
  display: flex;
  height: 250px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
`;

const Main = styled.div`
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const TextBox = styled.div`
  box-sizing: border-box;
  padding: 10px 30px;

  
`;

const Body = styled.p`
  margin: 10px;
  font-size: 1.3rem;
  padding-top: 20px;
`;

const BoldBody = styled.p`
  margin: 10px;
  font-size: 1.4rem;
  font-weight: bold;
`;


const UnorderedList = styled.ul`
  margin: 10px;
  font-size: 1.3rem;
`;



